const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2018 - 2020",
    title: "Master's in Management",
    desc: "Banaras Hindu University (BHU), Varanasi.",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2015 - 2018",
    title: "Bachelor's Degree",
    desc: "Mahatma Gandhi Kashi Vidyapeeth (MGKVP), Varanasi.",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-graduation",
    year: "2015",
    title: "Higher Secondary",
    desc: "Shivalik Model Sr. Sec. School, Nangal, Punjab.",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "Nov'22 - present",
    title: "Jr. Web Developer",
    desc: "Studio 24 Digital LLP, Noida.",
    short: "interated with clients",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "Sept'22 - Nov'22",
    title: "Jr. Web Developer",
    desc: "Kreartors Pvt Ltd (Digital Agency), Remote.",
    short: "interated with clients",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "Feb'21 - Sept'22",
    title: "Technical Support Executive",
    desc: "Manya - The Princeton Review, Delhi.",
    short: "interated with clients",
  },
];

export default Data;
