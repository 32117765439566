import Work1 from "../../assets/work/work1.svg";
import Work9 from "../../assets/work/work9.svg";
import Work8 from "../../assets/work/work8.svg";
import Work7 from "../../assets/work/work7.svg";
import Work6 from "../../assets/work/work6.svg";
import Work2 from "../../assets/work/work2.svg";
import Work3 from "../../assets/work/work3.svg";
import Work4 from "../../assets/work/work4.svg";
import Work5 from "../../assets/work/work5.svg";

// import Work1 from "../../assets/work-2.svg";
// import Work2 from "../../assets/work-2.svg";
// import Work3 from "../../assets/work-3.svg";
// import Work4 from "../../assets/work-4.svg";
// import Work5 from "../../assets/work-5.svg";

const Menu = [
  {
    id: 1,
    image: Work1,
    title: "White Magic Travels",
    category: "Modern Website",
    link: "https://www.whitemagictravels.co.uk/",
    desc: "It's a UK based flight search and travel website where you can book your flights and can also provides car rental services.",
  },
  {
    id: 2,
    image: Work2,
    title: "Malcolm Fearnside",
    category: "CMS Expertise",
    link: "https://malcolmfearnside.com/",
    desc: "A dedicated website for a book series named 'Bobby the Robin' written by Malcolm Fearnside.",
  },
  {
    id: 3,
    image: Work3,
    title: "Maple Publishers",
    category: "Modern Website",
    link: "https://maplepublishers.com/",
    desc: "A book publishing company. The company provides a wide range of services from book cover designing to proof reading services",
  },
  {
    id: 4,
    image: Work4,
    title: "Instant Notes App",
    category: "Modern Website",
    link: "https://avisingh.in/inotebook",
    desc: "Instant Notes is a react app, where you can add, remove and update notes. It also has user login option. To maintain backend Mongodb is used.",
  },
  {
    id: 5,
    image: Work5,
    title: "Foxwing Clothings",
    category: "CMS Expertise",
    link: "https://foxwing.in/",
    desc: "Foxwing is an e-commerce store headquartered in Chandigarh. They provide Tshirts, Hoodies and Sweatshirts. ",
  },
  {
    id: 6,
    image: Work6,
    title: "Business Flight Shop",
    category: "Modern Website",
    link: "https://www.businessflightshop.co.uk/",
    desc: "It's children website of White Magic Travels, dedicated to business class flights.",
  },
  {
    id: 7,
    image: Work7,
    title: "SStech LLC",
    category: "CMS Expertise",
    link: "https://sstech-llc.com/",
    desc: "It's an IT consulting firm engaged in the business of identifying and to provide professional personnel to meet the IT requirements",
  },
  {
    id: 8,
    image: Work8,
    title: "South Asia Pacific HR",
    category: "CMS Expertise",
    link: "https://saphr.ae/",
    desc: "SAPHR is developed on Wordpress and they provide manpower services to South Asia regions.",
  },
  {
    id: 9,
    image: Work9,
    title: "Farm Money Trading",
    category: "CMS Expertise",
    link: "https://farmmoney.in/",
    desc: "Designed a custom website for a financial consulting startup. ",
  },
];

export default Menu;
