import React from "react";

const HeaderSocials = () => {
  return (
    <div className="home__socials">
      <a
        href="https://www.instagram.com/singh_avinashh/"
        className="home__social-link"
        target="blank"
      >
        <i className="fa-brands fa-instagram"></i>
      </a>

      <a
        href="https://www.facebook.com/people/Avinash-Singh/pfbid03d3kqmctbVMnnrBneYDHhWLN1nyXzDG3hXcE32iJB55KyWkFVcFcX9gT1ny5LLZgl/"
        className="home__social-link"
        target="blank"
      >
        <i className="fa-brands fa-facebook"></i>
      </a>

      <a
        href="https://in.linkedin.com/in/avinash-singh-b811901b2"
        className="home__social-link"
        target="blank"
      >
        <i className="fa-brands fa-linkedin"></i>
      </a>

      <a
        href="https://www.snapchat.com/add/avinash_siiingh"
        className="home__social-link"
        target="blank"
      >
        <i className="fa-brands fa-snapchat"></i>
      </a>

      <a href="/" className="home__social-link" target="blank">
        <i className="fa-brands fa-github"></i>
      </a>
    </div>
  );
};

export default HeaderSocials;
